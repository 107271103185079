import { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from "@mui/material";
import { debounce } from "@web/utils/debounce";

export function StyledBox({ children, componentHeight, fixedOn, fixed, ...rest }) {
  return (
    <Box
      {...rest}
      sx={{
        '& .hold': {
          boxShadow: 'none',
          position: 'relative',
        },
        '& .fixed': {
          left: 0,
          right: 0,
          zIndex: 15,
          position: 'fixed',
          top: `${fixedOn}px`,
          transition: 'all 350ms ease-in-out',
          animation: `
            @keyframes slideDown {
              from {transform: translateY(-200%);}
              to {transform: translateY(0);}
            }
            400ms slideDown cubic-bezier(0.4, 0, 0.2, 1)
          `,
        },
        '& + .section-after-sticky': {
          paddingTop: fixed ? componentHeight : 0,
        },
      }}
    >
      {children}
    </Box>
  );
}
const Sticky = ({
  fixedOn,
  children,
  onSticky,
  containerRef,
  notifyPosition,
  notifyOnScroll,
  scrollDistance = 0,
}) => {
  const [fixed, setFixed] = useState(false);
  const [parentHeight, setParentHeight] = useState(0);
  const elementRef = useRef(null);
  const positionRef = useRef(0);
  const scrollListener = useCallback(debounce(() => {
    if (!window) return;

    // Distance of element from window top (-) minus value
    let distance = window.pageYOffset - positionRef.current;
    if (containerRef?.current) {
      let containerDistance =
        containerRef.current.offsetTop +
        containerRef.current?.offsetHeight -
        window.pageYOffset;
      if (notifyPosition && notifyOnScroll) {
        notifyOnScroll(
          distance <= notifyPosition && containerDistance > notifyPosition
        );
      }
      return setFixed(distance <= fixedOn && containerDistance > fixedOn);
    }
    if (notifyPosition && notifyOnScroll) {
      notifyOnScroll(distance >= notifyPosition);
    }
    let isFixed = distance >= fixedOn + scrollDistance;
    if (positionRef.current === 0) {
      isFixed =
        distance >= fixedOn + elementRef.current?.offsetHeight + scrollDistance;
    }
    setFixed(isFixed);
  }, 100), [containerRef, fixedOn, notifyOnScroll, notifyPosition, scrollDistance]);
  useEffect(() => {
    if (!window) return;
    window.addEventListener('scroll', scrollListener);
    window.addEventListener('resize', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('resize', scrollListener);
    };
  }, [scrollListener]);
  useEffect(() => {
    if (!positionRef.current) {
      positionRef.current = elementRef.current?.offsetTop;
    }
    setParentHeight(elementRef.current?.offsetHeight || 0);
  }, [children]);
  useEffect(() => {
    if (onSticky) onSticky(fixed);
  }, [fixed, onSticky]);
  return (
    <StyledBox fixedOn={fixedOn} componentHeight={parentHeight} fixed={fixed}>
      <div
        className={fixed ? 'fixed' : 'hold'}
        ref={elementRef}
      >
        {children}
      </div>
    </StyledBox>
  );
};
export default Sticky;
